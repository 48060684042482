// hot-reloading on dev
if (__DEV__) require("../pages/index.pug");

import lottie from "lottie-web";

const mouseScrollContainer = $(".cf-hero-scroll-more")[0];
const heroImgContainer = $(".cf-hero-content__img")[0];

const houseAnimation = lottie.loadAnimation({
  container: heroImgContainer,
  path: PUBLIC_PATH + "assets/animations/lander/data.json",
  renderer: "svg",
  autoplay: false,
  loop: false,
  rendererSettings: {
    progressiveLoad: true,
  },
});

const scrollAnimation = lottie.loadAnimation({
  container: mouseScrollContainer,
  path: PUBLIC_PATH + "assets/animations/scroll/data.json",
  renderer: "svg",
  autoplay: false,
  loop: false,
  rendererSettings: {
    progressiveLoad: true,
  },
});

const obs = new IntersectionObserver(
  (entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const { animation: animationSelector } = (entry.target as HTMLElement)
          .dataset;

        if (animationSelector === "hero-img") {
          houseAnimation.play();
        } else if (animationSelector === "scroll") {
          scrollAnimation.play();
        }
      }
    }
  },
  {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  }
);

setTimeout(() => {
  obs.observe(heroImgContainer);
  obs.observe(mouseScrollContainer);
});

const scrollMoreTarget = $(".cf-highlight-text")[0];

mouseScrollContainer.addEventListener("click", () => {
  scrollMoreTarget.scrollIntoView();
});
